












































































import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { DataFlowFullTable } from "@/healthcheck/model/DataFlowFullTable";

@Component
export default class HealthCheckJobsQueueView extends Vue {
  readonly jobInfoHeaders = [
    {
      text: this.$lang("jobInfoTable.headers.app"),
      align: "start",
      value: "application",
    },
    {
      text: this.$lang("jobInfoTable.headers.job"),
      value: "job",
    },
    {
      text: this.$lang("jobInfoTable.headers.count"),
      value: "count",
    },
    {
      text: this.$lang("jobInfoTable.headers.processingAvgTimeSec"),
      value: "processingAverageTimeSeconds",
    },
    {
      text: this.$lang("jobInfoTable.headers.totalAvgTimeSec"),
      value: "totalAverageTimeSeconds",
    },
    {
      text: this.$lang("jobInfoTable.headers.diffAvgTimeSec"),
      value: "diffAverageTimeSeconds",
    },
  ];
  readonly queueInfoHeaders = [
    {
      text: this.$lang("queueInfoTable.headers.queue"),
      align: "start",
      value: "queue",
    },
    {
      text: this.$lang("queueInfoTable.headers.job"),
      value: "job",
    },
    {
      text: this.$lang("queueInfoTable.headers.count"),
      value: "count",
    },
  ];
  readonly INTERVAL_TIME = 15000;

  searchJobInfo = "";
  searchQueueInfo = "";
  intervalId = 0;

  get isJobsQueueLoading(): boolean {
    return this.$store.state.healthCheckStore.isJobsQueueLoading;
  }

  get isJobsQueueExist(): boolean {
    return (
      this.$store.state.healthCheckStore.jobsQueueInfoResponse.length !== 0
    );
  }

  get jobsInfoTable(): Array<DataFlowFullTable> {
    return this.$store.state.healthCheckStore.jobsInfoTable;
  }

  get queuesInfoTable(): Array<DataFlowFullTable> {
    return this.$store.state.healthCheckStore.queuesInfoTable;
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("jobsQueueInfo.title")
    );

    this.$store.dispatch("getJobsQueue");

    this.intervalId = window.setInterval(() => {
      this.$store.dispatch("getJobsQueue");
    }, this.INTERVAL_TIME);
  }

  beforeDestroy() {
    clearInterval(this.intervalId);
  }
}
